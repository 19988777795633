<template>
  <WalletModal id="walletWithdraw" @close="onClose">
    <div class="wallet-form">
      <div class="matches-title">
        <h4>Withdraw</h4>
        <span class="s-a-1-txt"
          >Withdraw funds from your chopLife.com account</span
        >
      </div>

      <div class="form-wrapper1">
        <div
          v-if="message"
          class="alert"
          v-bind:class="{
            'alert-success': message.type === 'success',
            'alert-danger': message.type === 'error',
          }"
          role="alert"
        >
          {{ message.message }}
        </div>
        <input
          type="number"
          name="amount"
          id="deposit"
          placeholder="10"
          min="0"
          class="form-control std-input text-center"
          value="99"
          aria-label="Amount"
          v-model="amount"
        />

        <small style="color: var(--darkBackground)"
          >Minimum {{ currency }}. 50 Maximum {{ currency }}. 70,000</small
        >

        <button
          @click="handleWithdrawClick"
          id="deposit-profile"
          v-bind:class="loading"
          class="btn-std btn-sec btn-large"
          :disabled="loading"
        >
          {{ loading ? "Processing..." : "Withdraw" }}
        </button>
      </div>
    </div>
  </WalletModal>
</template>

<script>
import WalletModal from "./Modal.vue";
import walletserve from "@/services/walletserve";
import { pushToDataLayer } from "@/utils/gtm";

export default {
  name: "WalletDeposit",
  data: () => ({
    currency: process.env.VUE_APP_CURRENCY,
    loading: false,
    amount: null,
    message: null,
  }),
  components: {
    WalletModal,
  },
  methods: {
    onClose() {
      this.amount = null;
      this.message = null;
      this.loading = false;
    },

    handleWithdrawClick(event) {
      this.withdraw(event);
    },
    trackWithdrawButtonClick(event) {
      pushToDataLayer("gtm.withdraw", {
        category: "Button",
        action: "Click",
        label: "Withdraw",
        element: event.target,
      });
    },

    withdraw: function (event) {
      if (this.loading) {
        return;
      }

      var vm = this;

      this.loading = false;
      this.message = null;

      var p = this.getAuth();

      if (!p) {
        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: "login", params: {} });
        return;
      }

      if (this.amount < 50) {
        console.log(this.amount);

        this.message = {
          type: "error",
          message: `Invalid Amount. Enter amount at least 50 ${this.currency} or above`,
        };
        return;
      }

      var path = "/withdraw";

      var data = {
        amount: parseInt(this.amount),
        // msisdn: parseInt(this.msisdn),
      };

      this.loading = true;

      walletserve
        .post(path, data, {
          headers: {
            "api-key": vm.getAuth(),
          },
        })
        .then((res) => {
          this.trackWithdrawButtonClick(event);
          var msg = res.data.data;
          this.message = {
            message: `Withdrawal Initiated ${msg}`,
            type: "success",
          };
        })
        .catch((err) => {
          var msg = err.response.data.error_message;
          if (!vm.errorDisplayed) {
            if (parseInt(err.response)) {
              this.message = {
                message: msg,
                type: "error",
              };
              return;
            } else if (
              parseInt(err.response.data.error_code) === 401 ||
              parseInt(err.response.data.error_code) === 400 ||
              parseInt(err.response.data.error_code) === 428
            ) {
              vm.setError(
                "Session Expired",
                "Your session on this device has expired"
              );
              vm.logout();
              return;
            } else {
              this.message = {
                message: err.response?.data?.error_message ?? "Failed",
                type: "error",
              };
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style scoped>
.wallet-form {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 0.5rem;
}

.form-wrapper1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.form-wrapper1 input {
  background: var(--primary-bg-light);
  color: white;
}

h4 {
  margin: 0;
  margin-bottom: 0.5rem;
}

.form-wrapper1 {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.alert {
  position: relative;
  border-radius: 0.5rem;
}

.wallet-btns-grid {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
}

.wallet-btns-grid .odd-btn {
  padding: 0.2rem;
  font-size: 0.8rem;
  background: var(--light-gray);
  color: var(--primary);
  border-radius: 9999px;
  width: auto;
}
</style>
